<template>
  <div>
    <div class="container">
      <div class="flex flex-row items-center mb-6" style="margin-top: 80px;">
        <div class="flex flex-row items-center w-1/2">
          <div class="border-red-600 border-r-4 h-10 w-1 mr-2"></div>
          <div class="sm:text-lg md:text-2xl lg:text-3xl font-bold">
            ความรู้ด้านเทคโนโลยี : ข้อมูลวิชาการ
          </div>
        </div>
      </div>

      <div class="cardContent w-full mb-4" v-for="(item, index) in items" :key="index">
        <div class="p-10">
          <div class="content mb-12">
            {{item.title}}
          </div>

          <div>
              <button>ดูเพิ่มเติม</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ref} from 'vue';
export default {
    setup() {
        const items = ref([
            {
                title: '1.บทวิเคราะห์ผลการจัดอันดับความสามารถในการแข่งขันของประเทศไทย โดยสถาบันการจัดการนานาชาติ (IMD World Competitiveness Ranking) ปี 2562 ในมิติที่เกี่ยวข้องกับการพัฒนาดิจิทัลเพื่อเศรษฐกิจและสังคม',
            },
            {
                title: '2.การวัดระดับการเติบทางเศรษฐกิจจากการวิเคราะห์ผลิตภัณฑ์มวลรวมในประเทศอันเป็นผลจากเทคโนโลยีดิจิทัล (Digital Contribution to GDP)',
            },
            {
                title: '3.ผลการจัดอันดับความสามารถในการแข่งขันของประเทศไทยโดยสถาบัน IMD ปี 2563 ในมิติที่เกี่ยวข้องกับการพัฒนาดิจิทัลเพื่อเศรษฐกิจและสังคม',
            },
        ]);
        return {items};
    }
};
</script>

<style scoped>
.container {
  font-family: KanitFont;
}

.cardContent {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.content {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;

  color: #3d3d3d;
}

button {
  width: 240px;
  height: 48px;
  background: #7b55b5;
  border-radius: 30px;
  color: white;
}
</style>